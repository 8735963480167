/* eslint-disable */
import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, NavbarBrand, Navbar, Nav, NavItem, NavbarToggler, Collapse } from 'reactstrap';

import logo from '../assets/images/logos/Logo_NoBackground.png';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    /*--------------------------------------------------------------------------------*/
    /*To open NAVBAR in MOBILE VIEW                                                   */
    /*--------------------------------------------------------------------------------*/

    return (
        <div className="topbar" id="top">
            <div className="header6">
                <Container className="po-relative">
                    <Navbar className="navbar-expand-lg h6-nav-bar">
                    <NavbarBrand href="/"><img src={logo} alt="logo" style={{ width: '100px', height: '120px' }} /></NavbarBrand>
                        <NavbarToggler onClick={toggle}><span className="ti-menu"></span></NavbarToggler>
                        <Collapse isOpen={isOpen} navbar className="hover-dropdown font-14 justify-content-end" id="h6-info">
                            <Nav navbar className="ms-auto">
                                <NavItem>
                                    <Link className="nav-link" to={"/"} style={{ color: 'black' }}>
                                        Homepage
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to={"/history"} style={{ color: 'black' }}>
                                        History
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to={"/projects"} style={{ color: 'black' }}>
                                        Projects
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to={"/team"} style={{ color: 'black' }}>
                                        Team
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to={"/data"} style={{ color: 'black' }}>
                                        Data
                                    </Link>
                                </NavItem>                                
                            </Nav>
                            <div className="act-buttons">
                            <a href="https://alberta.campuslabs.ca/engage/organization/red" className="btn btn-success-gradiant font-14" target="_blank" rel="noopener noreferrer">Join our club on Bearsden</a>
                            </div>
                        </Collapse>
                    </Navbar>
                </Container>
            </div>
        </div>
    );

}
export default Header;
