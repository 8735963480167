import React from "react";
import "./assets/scss/style.scss";
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from "history";
import {
  Route,
  Routes,
  HashRouter
} from "react-router-dom";

// pages for this product
// import Components from "./pages/components.jsx";
// import CustomComponents from "./pages/custom-components.jsx";
import HomePage from "./pages/homepage.jsx";
import HistoryPage from "./pages/history.jsx";
import ProjectsPage from "./pages/projects.jsx";
import TeamPage from "./pages/team.jsx";
import DataPage from "./pages/data.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'));

var hist = createBrowserHistory();
root.render(
  <HashRouter history={hist}>
    <Routes>
      {/* <Route path="/" element={<Components />} /> */}
      {/* <Route path="/custom-components" element={<CustomComponents />} /> */}
      
      <Route path="/" element={<HomePage />} />
      <Route path="/history" element={<HistoryPage />} />
      <Route path="/projects" element={<ProjectsPage />} />
      <Route path="/team" element={<TeamPage />} />
      <Route path="/data" element={<DataPage />} />
    </Routes>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
