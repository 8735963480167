import React from 'react';

import { Alert, Container, Row, Col } from 'reactstrap';

const Notification = () => {
    return (
        <div>
            <div className="spacer" id="notification-component">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">History</h1>
                            <h6 className="subtitle">The historical milestones of our club.</h6>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col md="12">
                        <Alert color="success">
                            2018 - The club was founded by Larry Zhong
                            </Alert>
                        <Alert color="danger">
                            2019 - The club completed its first project, the mobile solar charging station
                            </Alert>
                        <Alert color="warning">
                            2020 - The club is working with sponsors SolSpace to build a Net-Zero Solar Greenhouse
                            </Alert>
                        <Alert color="info">
                            2021 - The solar greenhouse is commissioned and operational
                            </Alert>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Notification;
