/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';

import img1 from '../assets/images/organizations/solspace.png';
import img2 from '../assets/images/organizations/shell-logo.png';
import img3 from '../assets/images/organizations/uofa.png';
import img4 from '../assets/images/organizations/sustainsu.jpeg';
import img5 from '../assets/images/organizations/esw.png';
import img6 from '../assets/images/organizations/permacultureclub.jpeg';
import img7 from '../assets/images/organizations/Sinkunia-Banner.jpg';

const PortfolioComponent = () => {
    return (
        <div>
            {/* <div className="spacer bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Portfolio</h1>
                            <h6 className="subtitle">Here you can check Demos we created based on WrapKit. Its quite easy to Create your own dream website &amp; dashboard in No-time.</h6>
                        </Col>
                    </Row>
                </Container>
            </div> */}
            <div className="spacer">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h2 className="title">Our sponsors</h2>
                            <h6 className="subtitle">Organizations that have supported us!</h6>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={img1} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">SolSpace</h5>
                                    {/* <p className="m-b-0 font-14">Digital Marketing</p> */}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={img2} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Shell</h5>
                                    {/* <p className="m-b-0 font-14">Search Engine</p> */}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={img3} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Faculty of Engineering</h5>
                                    {/* <p className="m-b-0 font-14">Admin templates</p> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h2 className="title">Our collaborators</h2>
                            <h6 className="subtitle">Organizations that we like!</h6>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                    <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={img4} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">SustainSU</h5>
                                    {/* <p className="m-b-0 font-14">Digital Marketing</p> */}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={img5} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Engineers for a Sustainable World</h5>
                                    {/* <p className="m-b-0 font-14">Digital Marketing</p> */}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={img6} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Permaculture Club</h5>
                                    {/* <p className="m-b-0 font-14">Search Engine</p> */}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={img7} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Sikunia</h5>
                                    {/* <p className="m-b-0 font-14">Admin templates</p> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default PortfolioComponent;
