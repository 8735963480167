/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card } from 'reactstrap';

import img1 from '../assets/images/blog/blog-home/20240715_104956.jpg';
import img2 from '../assets/images/blog/blog-home/20240715_104739.jpg';
import img3 from '../assets/images/blog/blog-home/IMG_4745.JPG';

const BlogComponent = () => {
    return (
        <div>
            {/* <div className="spacer bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Blog</h1>
                            <h6 className="subtitle">Here you can check Demos we created based on WrapKit. Its quite easy to Create your own dream website &amp; dashboard in No-time.</h6>
                        </Col>
                    </Row>
                </Container>
            </div> */}
            <div className="blog-home2 spacer">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8" className="text-center">
                            <h2 className="title">Notable Articles</h2>
                            <h6 className="subtitle">Check out some of the featured productions of our club!</h6>
                        </Col>
                    </Row>
                    <Row className="m-t-40 justify-content-center">
                        <Col lg="4" md="6">
                            <Card>
                                <a href="#/projects#greenhouse-summer-2024"><img className="card-img-top" src={img1} alt="wrappixel kit" /></a>
                                <div className="date-pos bg-info-gradiant">Summer<span>2024</span></div>
                                <h5 className="font-medium m-t-30"><a href="#/projects#greenhouse-summer-2024" className="link">The plants are growing in the greenhouse.</a></h5>
                                <p className="m-t-20">Summer 2024 Greenhouse progress</p>
                                <a href="#/projects#greenhouse-summer-2024" className="linking text-themecolor m-t-10">See More <i className="ti-arrow-right"></i></a>
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card>
                                <a href="#/projects/#greenhouse-automation"><img className="card-img-top" src={img2} alt="wrappixel kit" /></a>
                                {/* <div className="date-pos bg-info-gradiant">Oct<span>23</span></div> */}
                                <h5 className="font-medium m-t-30"><a href="#/projects/#greenhouse-automation" className="link">Data is now being logged at the greenhouse.</a></h5>
                                <p className="m-t-20">Solar Greenhouse's Home Assistant</p>
                                <a href="#/projects/#greenhouse-automation" className="linking text-themecolor m-t-10">See More <i className="ti-arrow-right"></i></a>
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card>
                                <a href="#/projects/#wind-turbine-testing"><img className="card-img-top" src={img3} alt="wrappixel kit" /></a>
                                {/* <div className="date-pos bg-info-gradiant">Oct<span>23</span></div> */}
                                <h5 className="font-medium m-t-30"><a href="#/projects/#wind-turbine-testing" className="link">Cold wind spells hot wires.</a></h5>
                                <p className="m-t-20">Wind Turbine prototype testing</p>
                                <a href="#/projects/#wind-turbine-testing" className="linking text-themecolor m-t-10">See More <i className="ti-arrow-right"></i></a>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default BlogComponent;
