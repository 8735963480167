/* eslint-disable */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
    return (
        <div className="footer4 b-t spacer">
            <Container>
                <Row>
                    {/* <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Address</h5>
                        <p>71 Amsteroum Avenue Cronish Night, NY 35098</p>
                    </Col>
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Phone</h5>
                        <p>Reception :  +205 123 4567 <br />Office :  +207 235 7890</p>
                    </Col>
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Email</h5>
                        <p>Office :  <a href="#" className="link">info@wrappixel.com</a> <br />
                        Site :  <a href="#" className="link">wrapkit@wrappixel.com</a></p>
                    </Col> */}

                    <Col lg="3" md="6">
                        <h5 className="m-b-20">Social Media</h5>
                        <div className="round-social light">
                            <a href="#" target="_blank" className="link"><i className="fa fa-facebook"></i></a>
                            <a href="#" target="_blank" className="link"><i className="fa fa-instagram"></i></a>
                            <a href="#" target="_blank"className="link"><i className="fa fa-twitter"></i></a>
                            <a href="https://www.linkedin.com/company/renewable-energy-design/" target="_blank" className="link"><i className="fa fa-linkedin"></i></a>
                            <a href="#" target="_blank" className="link"><i className="fa fa-youtube-play"></i></a>
                        </div>
                    </Col>                    
                    <div className="act-buttons">
                            <a href="https://alberta.campuslabs.ca/engage/organization/red" className="btn btn-success-gradiant font-14" target="_blank" rel="noopener noreferrer">Join our club on Bearsden</a>
                    </div>
                </Row>
                <div className="f4-bottom-bar">
                    <Row>
                        <Col md="12">
                            <div className="d-flex font-14 justify-content-between">
                                <div className="m-t-10 m-b-10 copyright">All Rights Reserved by: Renewable Energy Design - UAlberta student club.</div>
                                {/* <div className="links ms-auto m-t-10 m-b-10">
                                    <a href="#" className="p-10 p-l-0">Terms of Use</a>
                                    <a href="#" className="p-10">Legal Disclaimer</a>
                                    <a href="#" className="p-10">Privacy Policy</a>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}
export default Footer;
