import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../components/header.jsx";
// import HeaderBanner from "../components/banner.jsx";
import Footer from "../components/footer.jsx";

// import HeaderBanner2 from "../components/banner2.jsx";

// section_components for this page
// import Buttons from "../section_components/buttons.jsx";
// import Labels from "../section_components/labels.jsx";
// import PagePagination from "../section_components/pagination.jsx";
// import Images from "../section_components/images.jsx";
// import Breadcrumbs from "../section_components/breadcrumbs.jsx";
// import Cards from "../section_components/cards.jsx";
// import Dropdowns from "../section_components/dropdowns.jsx";
// import PageForm from "../section_components/form.jsx";
// import PageTable from "../section_components/table.jsx";
import Notification from "../section_components/notification.jsx";
// import TooltipPopover from "../section_components/tooltip-popover.jsx";
// import Typography from "../section_components/typography.jsx";
// import JsComponents from "../section_components/js-components.jsx";
import CallToAction from "../section_components/CallToAction.jsx"

// import HeaderComponent from "../section_components/headercomponent.jsx";
// import BannerComponent from "../section_components/bannercomponent.jsx";
// import FormBannerComponent from "../section_components/formbannercomponent.jsx";
// import FeatureComponent from "../section_components/featurecomponent.jsx";
// import PortfolioComponent from "../section_components/portfoliocomponent.jsx";
// import PricingComponent from "../section_components/pricingcomponent.jsx";
// import TeamComponent from "../section_components/teamcomponent.jsx";
// import TestimonialComponent from "../section_components/testimonialcomponent.jsx";
// import BlogComponent from "../section_components/blogcomponent.jsx";
// import C2aComponent from "../section_components/c2acomponent.jsx";
// import ContactComponent from "../section_components/contactcomponent.jsx";



const HistoryPage = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                {/* <HeaderBanner /> */}
                    {/* <Buttons />
                    <Labels />
                    <PagePagination />
                    <Images />
                    <Breadcrumbs />
                    <Cards />
                    <Dropdowns />
                    <PageForm />
                    <PageTable /> */}
                    <Notification />
                    {/* <TooltipPopover />
                    <Typography />
                    <JsComponents /> */}
                    <CallToAction />
                    
                    {/* <HeaderBanner2 />
                    <HeaderComponent />
                    <BannerComponent />
                    <FormBannerComponent />
                    <FeatureComponent />
                    <PortfolioComponent />
                    <PricingComponent />
                    <TeamComponent />
                    <TestimonialComponent />
                    <BlogComponent />
                    <C2aComponent />
                    <ContactComponent /> */}
                </div>
            </div>
            <Footer />
        </div>
    );
}

HistoryPage.propTypes = {
    classes: PropTypes.object
};

export default HistoryPage;
