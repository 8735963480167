import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

// core components
import Header from "../components/header.jsx";
// import HeaderBanner from "../components/banner.jsx";
import Footer from "../components/footer.jsx";

// import HeaderBanner2 from "../components/banner2.jsx";

// section_components for this page
// import Buttons from "../section_components/buttons.jsx";
// import Labels from "../section_components/labels.jsx";
// import PagePagination from "../section_components/pagination.jsx";
// import Images from "../section_components/images.jsx";
// import Breadcrumbs from "../section_components/breadcrumbs.jsx";
// import Cards from "../section_components/cards.jsx";
// import Dropdowns from "../section_components/dropdowns.jsx";
// import PageForm from "../section_components/form.jsx";
// import PageTable from "../section_components/table.jsx";
// import Notification from "../section_components/notification.jsx";
// import TooltipPopover from "../section_components/tooltip-popover.jsx";
// import Typography from "../section_components/typography.jsx";
// import JsComponents from "../section_components/js-components.jsx";
// import CallToAction from "../section_components/CallToAction.jsx";

// import HeaderComponent from "../section_components/headercomponent.jsx";
// import BannerComponent from "../section_components/bannercomponent.jsx";
// import FormBannerComponent from "../section_components/formbannercomponent.jsx";
// import FeatureComponent from "../section_components/featurecomponent.jsx";
// import PortfolioComponent from "../section_components/portfoliocomponent.jsx";
// import PricingComponent from "../section_components/pricingcomponent.jsx";
// import TeamComponent from "../section_components/teamcomponent.jsx";
// import TestimonialComponent from "../section_components/testimonialcomponent.jsx";
// import BlogComponent from "../section_components/blogcomponent.jsx";
// import C2aComponent from "../section_components/c2acomponent.jsx";
// import ContactComponent from "../section_components/contactcomponent.jsx";

import img1 from "../assets/images/projectsimages/20240715_104956.jpg";
import img2 from "../assets/images/projectsimages/20240811_113509.jpg";
import img3 from "../assets/images/projectsimages/20240816_131439.jpg";

import img4 from "../assets/images/projectsimages/IMG_4745.JPG";
import img5 from "../assets/images/projectsimages/IMG_0796.JPG";
import img6 from "../assets/images/projectsimages/IMG_5632.JPG";

import img7 from "../assets/images/projectsimages/20240715_104739.jpg";
import img8 from "../assets/images/projectsimages/20240811_113450.jpg";

const ProjectsPage = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* <HeaderBanner /> */}
          {/* <Buttons />
                    <Labels />
                    <PagePagination />
                    <Images />
                    <Breadcrumbs />
                    <Cards />
                    <Dropdowns />
                    <PageForm />
                    <PageTable />
                    <Notification />
                    <TooltipPopover />
                    <Typography />
                    <JsComponents />
                    <CallToAction />
                    
                    <HeaderBanner2 />
                    <HeaderComponent />
                    <BannerComponent />
                    <FormBannerComponent />
                    <FeatureComponent />
                    <PortfolioComponent />
                    <PricingComponent />
                    <TeamComponent />
                    <TestimonialComponent />
                    <BlogComponent />
                    <C2aComponent />
                    <ContactComponent /> */}
            <div className="spacer" id="imgs-component">
              <Container>
                <Row className="justify-content-center">
                  <Col md="7" className="text-center">
                    <h1 className="title font-bold">Projects</h1>
                    <h6 className="subtitle">
                      Here you can see our projects and notable updates.
                    </h6>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container>
              <Row>
                {/* <Col lg="4" className="m-b-30"> */}
                  <h4 className="card-title" id="greenhouse-summer-2024">Greenhouse Summer 2024</h4>
                  {/* <h6 className="card-subtitle">
                    <code>.img-rounded</code>
                  </h6> */}
                  <img
                    src={img1}
                    alt="img"
                    className="img-responsive img-rounded"
                    width="200"
                  />
                <img
                    src={img2}
                    alt="img"
                    className="img-responsive img-rounded"
                    width="200"
                  />
                <img
                    src={img3}
                    alt="img"
                    className="img-responsive img-rounded"
                    width="200"
                  />
                  <p className="m-t-15 m-b-0"> The greenhouse is growing a bunch of plants, they excel in growth in here!</p>
                {/* </Col> */}
              </Row>
              <Row>
                {/* <Col lg="4" className="m-b-30"> */}
                  <h4 className="card-title" id="wind-turbine-testing">Wind Turbine Testing</h4>
                  {/* <h6 className="card-subtitle">
                    <code>.img-rounded</code>
                  </h6> */}
                  <img
                    src={img4}
                    alt="img"
                    className="img-responsive img-rounded"
                    width="200"
                  />
                <img
                    src={img5}
                    alt="img"
                    className="img-responsive img-rounded"
                    width="200"
                  />
                <img
                    src={img6}
                    alt="img"
                    className="img-responsive img-rounded"
                    width="200"
                  />
                  <p className="m-t-15 m-b-0">After some wind tunnel testing, it's time to try the prototype in a real environment!</p>
                {/* </Col> */}
              </Row>
              <Row>
                {/* <Col lg="4" className="m-b-30"> */}
                  <h4 className="card-title" id="greenhouse-automation">Greenhouse Automation</h4>
                  {/* <h6 className="card-subtitle">
                    <code>.img-rounded</code>
                  </h6> */}
                  <img
                    src={img7}
                    alt="img"
                    className="img-responsive img-rounded"
                    width="200"
                  />
                <img
                    src={img8}
                    alt="img"
                    className="img-responsive img-rounded"
                    width="200"
                  />

                  <p className="m-t-15 m-b-0">We have data collection now, thanks to a raspberry pi! </p>
                {/* </Col> */}
              </Row>
            </Container>

        </div>
      </div>
      <Footer />
    </div>
  );
};

ProjectsPage.propTypes = {
  classes: PropTypes.object,
};

export default ProjectsPage;
